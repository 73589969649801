@tailwind base;
@tailwind components;
@tailwind utilities;
.popho:hover{
    background-color: #D5FFD5;
    cursor: pointer;
    color: black;
}
.strn:hover{
    background-color: #D5FFD5;
    cursor: pointer;
    color: black;
    border: 1px solid lightgray;
    height: 30px;
    padding-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    
   
}

.dsp{
    
        padding: 20px;
        /* display: grid;
        grid-template-columns: 30% 30% 30%;
        gap: 3%; */
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        
        
      
}
@media(max-width:900px){
    .dsp{
        grid-template-columns: 100% 100% 100%;
    }
    
}
.d-none{
display: none;
}
.d.true{
    display: flex;
}
